<template>
    <div class="vstack gap-3">
        <div class="vstack align-items-center gap-2 bg-green rounded shadow-sm p-4">
            <span :class="`rounded-circle p-4 text-white bg-white`">
                <b-icon class="d-flex text-purple" icon="kanban-fill" font-scale="4"/>
            </span>
            <div class="text-white fs-4">Novo Kanban</div>
            <b-button variant="light" class="text-purple px-4" v-b-modal.create>Criar</b-button>
        </div>
        <div class="bg-white rounded text-purple shadow-sm">
            <div class="hstack justify-content-between p-4 border-bottom-grey">
                <div class="fs-5 fw-semibold">
                    Kanbans
                </div>
                <div class="d-flex align-items-center gap-3">
                    <div class="text-nowrap">Total de Kanbans</div>
                    <div class="fs-5 bg-purple text-white rounded-pill px-2">{{ totalKanbans }}</div>
                    <!-- <b-button class="bg-green border-green rounded" v-b-modal.filter>Filtrar</b-button> -->
                </div>
            </div>
            <div class="d-flex justify-content-center py-4" v-if="loading">
                <b-spinner variant="purple"></b-spinner>
            </div>
            <div v-else-if="kanbans.length">
                <b-row class="m-0 py-3 px-4 border-bottom-grey">
                    <b-col class="p-0 text-purple fw-semibold">
                        Nome
                    </b-col>
                    <b-col class="p-0 text-purple fw-semibold">
                        Fases
                    </b-col>
                    <b-col class="p-0 text-purple fw-semibold">
                        Departamento
                    </b-col>
                    <b-col cols="2">
                    </b-col>
                </b-row>
                <b-row class="m-0 py-3 px-4 border-bottom-grey align-items-center" v-for="kanban in kanbans" :key="kanban.id">
                    <b-col class="p-0 text-purple">
                        {{ kanban.name }}
                    </b-col>
                    <b-col class="p-0 text-purple">
                        <span v-for="(step, i) in kanban.steps" :key="step._id">
                            <span>
                                {{ step.name }}<span v-if="i+1<kanban.steps.length">,</span> 
                            </span>
                        </span>
                    </b-col>
                    <b-col class="p-0 text-purple">
                        <span v-for="(dep,i) in kanban.association" :key="dep?.id">
                            <span>
                                {{ depName(dep.id) }}<span v-if="i+1<kanban.association.length">,</span> 
                            </span>
                        </span>
                    </b-col>
                    <b-col class="hstack justify-content-end gap-2" cols="2">
                        <b-button class="text-purple p-2" variant="purple" @click="viewKanban(kanban)" v-b-tooltip.hover.bottom="{ variant: 'purple', customClass: 'top-0', boundary: 'document' }" title="Visualizar">
                            <b-icon class="text-white" icon="eye"/>
                        </b-button>
                        <b-button class="text-purple p-2" variant="green" @click="selectKanban(kanban)" v-b-modal.create v-b-tooltip.hover.bottom="{ variant: 'green', customClass: 'top-0', boundary: 'document' }" title="Editar">
                            <b-icon class="text-white" icon="pencil-square"/>
                        </b-button>
                        <b-button class="text-purple p-2" variant="red" @click="selectKanban(kanban)" v-b-modal.remove v-b-tooltip.hover.bottom="{ variant: 'red', customClass: 'top-0', boundary: 'document' }" title="Remover">
                            <b-icon class="text-white" icon="trash"/>
                        </b-button>
                    </b-col>
                </b-row>
            </div>
            <div class="text-secondary text-center py-4" v-else>
                Nenhum Kanban encontrado!
            </div>
        </div>
        <div class="d-flex justify-content-between align-items-center px-2 py-3 bg-white" v-if="totalKanbans > perPage">
            <b-pagination class="m-0"
                :total-rows="totalKanbans"
                v-model="currentPage"
                :per-page="perPage"
                @change="pageChange"
            ></b-pagination>
            <span>
                <div class="text-secondary">{{ ( currentPage > 1 ) ? ((currentPage) * perPage) - perPage + 1 : currentPage }} - {{ (currentPage * perPage > totalKanbans) ? totalKanbans : currentPage * perPage }} de {{ totalKanbans }} Kanbans</div>
            </span>
        </div>
        <b-modal id="create" ref="create" :title="`${objAux._id ? 'Editar' : 'Criar'} Kanban`" hide-footer header-class="p-0 px-3" body-class="p-0">
            <div class="vstack gap-3 p-3">
                <b-form-group label="Nome:" label-class="small-text fw-normal text-secondary">
                    <b-form-input class="border-grey" v-model="objAux.name" required></b-form-input>
                </b-form-group>
                <div class="vstack gap-3">
                    <div class="hstack justify-content-between gap-3">
                        <div class="small-text text-secondary">Fases:</div>
                        <b-iconstack class="darkenTextOnHover" font-scale="1.5" role="button" @click="addStep">
                            <b-icon class="text-purple" stacked icon="circle-fill"></b-icon>
                            <b-icon stacked icon="plus" variant="light"></b-icon>
                        </b-iconstack>
                    </div>
                    <div v-for="(step,i) in objAux.steps" :key="step._id || i">
                        <div class="hstack gap-3" v-if="!step.delete">
                            <b-form-input class="border-grey" v-model="step.name" required></b-form-input>
                            <b-button variant="red" @click="deleteStep(step)">
                                <b-icon icon="trash" variant="light"></b-icon>
                            </b-button>
                        </div>
                    </div>
                </div>
                <b-form-group label="Departamento:" label-class="small-text fw-normal text-secondary">
                    <div class="position-relative" v-if="objAux.association">
                        <b-form-select class="rounded border-grey p-1 w-100" v-model="objAux.association[0]">
                            <option v-for="dep in departments" :key="dep.id" :value="{ type: 'department', id: dep._id}">{{ depName(dep._id) }}</option>
                        </b-form-select>
                        <!-- <div class="border rounded w-100 py-1 px-3 d-flex justify-content-between" style="border-color: #eee !important" role="button" v-b-toggle.collapseDepSelect>
                            <span v-if="objAux.association && objAux.association.length">
                                <span v-for="(dep,i) in objAux.association.map(({ id, type }) => ({ id, type }))" :key="dep.id">
                                    <span>
                                        {{ depName(dep.id) }}<span v-if="i+1 < objAux.association.length">,</span> 
                                    </span>
                                </span>
                            </span>
                            <span v-else>
                                &nbsp;
                            </span>
                            <span>
                                <b-icon class="text-dark" icon="chevron-down" style="margin-right: -0.9em" font-scale="0.75"></b-icon>
                            </span>
                        </div>
                        <b-collapse id="collapseDepSelect" ref="collapseDepSelect" class="mt-2 position-absolute bg-white border w-100 rounded" style="z-index: 1">
                            <b-form-checkbox-group
                                v-model="objAux.association"
                                style="z-index: 1"
                            >
                                <div v-for="item in departments" :key="item._id">
                                    <b-form-checkbox class="text-secondary small-text d-flex align-items-center border p-3 w-100" role="button" :value="{ type: 'department', id: item._id}"> 
                                        <div class="ms-1" role="button">{{ item.name }}</div>
                                    </b-form-checkbox>
                                </div>
                            </b-form-checkbox-group>
                            <div class="w-100 h-100 position-fixed top-0 end-0 start-0 bottom-0" role="window" style="z-index: -1" v-b-toggle.collapseDepSelect>
                            </div>
                        </b-collapse> -->
                    </div>
                </b-form-group>
            </div>
            <div class="hstack gap-3 p-3">
                <b-button type="submit" class="text-white" variant="green" @click="saveKanban()">Salvar</b-button>
                <b-button class="border-grey bg-white text-secondary" @click="$bvModal.hide('create')">Cancelar</b-button>
            </div>
        </b-modal>
        <b-modal id="remove" ref="remove" title="Remover Kanban" hide-footer size="sm" header-class="border-0 p-0 px-3" body-class="p-0">
            <div class="border-bottom border-top text-secondary smaller-text p-3">
                Tem certeza de que deseja remover este Kanban?
            </div>
            <div class="hstack gap-3 p-3">
                <b-button class="text-white" type="submit" variant="red" @click="deleteKanban()">Remover</b-button>
                <b-button class="border-grey bg-white text-secondary" @click="$bvModal.hide('remove')">Cancelar</b-button>
            </div>
        </b-modal>
        <b-modal id="filter" ref="filter" title="Filtrar Kanbans" hide-footer header-class="p-0 px-3" body-class="p-0">
            <div class="vstack gap-3 p-3">
                <b-form-group label="Selecione o filtro:">
                    <b-form-select class="border-grey w-100 p-2 rounded" v-model="filters.type">
                        <b-form-select-option value="name">Nome</b-form-select-option>
                        <b-form-select-option value="department">Departamento</b-form-select-option>
                    </b-form-select>
                </b-form-group>
                <div v-if="filters.type">
                    <b-form-input v-model="filters.value" placeholder="Busca por Nome" class="border-grey" v-if="filters.type == 'name'"></b-form-input>
                    <b-form-group label="Departamento:" v-else-if="filters.type == 'department'">
                        <b-form-select class="border-grey w-100 p-2 rounded" v-model="filters.value">
                            <b-form-select-option v-for="item in departments" :key="item.id" :value="item._id">{{item.name}}</b-form-select-option>
                        </b-form-select>
                    </b-form-group>
                </div>
            </div>
            <div class="hstack gap-3 p-3">
                <b-button class="text-white" type="submit" variant="green" @click="getKanbans(1)">Filtrar</b-button>
                <b-button class="border-grey bg-white text-secondary" @click="$bvModal.hide('filter')">Cancelar</b-button>
            </div>
        </b-modal>
    </div>
</template>

<script>
import api from "../services/apiService.js"

export default {
    props:[
        'user'
    ],
    mounted() {
        this.getKanbans(1)
        this.getDepartments()
        this.onCloseModal()
    },
    data() {
        return {
            kanbans: [],
            departments: [],
            totalKanbans: 0,
            objAux: {
                steps: [],
                association: []
            },
            currentPage: 1,
            perPage: 10,
            filters: {
                type: null,
                value: null
            },
            loading: false,
            isFiltered: false,
        }
    },
    methods: {
        onCloseModal() {
            this.$root.$on('bv::modal::hide', () => {
                this.objAux = {
                    steps: [],
                    association: []
                }
            })
        },
        async getKanbans(page = 1) {
            this.loading = true
            if (this.filters.type) {
                const resp = await api.getKanbansFilter(this.user.channelId || this.user.roleId, this.filters)
                console.log({resp})
                if(resp.statusCode == 200) {
                    this.kanbans = resp.kanban
                    this.totalKanbans = resp.kanban.length
                    this.isFiltered = true
                } else {
                    this.kanbans = []
                    this.totalKanbans = 0
                    this.isFiltered = true
                }
            } else {
                const resp = await api.getKanbans(this.user.channelId || this.user.roleId, page)
                console.log({resp})
                if(resp.statusCode == 200) {
                    const promise = resp.kanbans.map(async kanban => {
                        const resp = await api.getKanbanSteps(kanban._id)
                        if(resp.statusCode == 200) {
                            kanban.steps = resp.steps
                        }
                        return kanban
                    })
                    await Promise.all(promise)

                    this.kanbans = resp.kanbans
                    this.totalKanbans = resp.total
                    this.isFiltered = false
                } else {
                    this.kanbans = []
                    this.totalKanbans = 0
                    this.isFiltered = false
                }
            }
            
            if (this.currentPage != page) {
                this.currentPage = page
            }
            this.loading = false
        },
        async saveKanban() {
            if (this.objAux._id) {
                if (this.objAux.steps) {
                    for (const step in this.objAux.steps.toReversed()) {
                        if (this.objAux.steps[step].delete) {
                            await api.deleteKanbanStep(this.objAux.steps[step]._id)
                        } else if (this.objAux.steps[step].isNew) {
                            if (!this.objAux.steps[step].name) {
                                return this.$emit('msg', {
                                    text: "Preencha todos os campos!",
                                    success: false
                                })
                            }

                            if (!this.objAux.steps[step].order)
                                this.objAux.steps[step].order = parseInt(step) + 1
    
                            const resp = await api.createKanbanStep(this.objAux._id, this.objAux.steps[step])
                            if (resp.statusCode != 200) {
                                console.log({resp})
                            }
                        } else {
                            if (!this.objAux.steps[step].order)
                                this.objAux.steps[step].order = parseInt(step) + 1

                            const resp = await api.updateKanbanStep(this.objAux.steps[step])
                            if (resp.statusCode != 200) {
                                console.log({resp})
                            }
                        }
                    }
                }

                const resp = await api.updateKanban(this.objAux)
                console.log({resp})
                if (resp.statusCode == 200) {
                    this.getKanbans()
                    this.$bvModal.hide('create')
                } else {
                    this.$emit('msg', {
                        text: "Ocorreu um erro ao editar o kanban!",
                        success: false
                    })
                }
            } else {
                this.objAux.channelId = this.user.channelId || this.user.roleId
                console.log(this.objAux)
                const resp = await api.createKanban(this.objAux)
                console.log({resp})
                if (resp.statusCode == 200 || resp.statusCode == 201) {
                    for (const index in this.objAux.steps) {
                        const step = this.objAux.steps[index]
                        console.log({step})
                        if (!step.name) {
                            return this.$emit('msg', {
                                text: "Preencha todos os campos!",
                                success: false
                            })
                        }
                        
                        if (!step.order) {
                            step.order = parseInt(index) + 1
                        }
                        
                        const respCreate = await api.createKanbanStep(resp.kanban._id, step)
                        if (respCreate.statusCode != 200) {
                            console.log({respCreate})
                        }
                    }

                    this.getKanbans()
                    this.$bvModal.hide('create')
                } else {
                    this.$emit('msg', {
                        text: "Ocorreu um erro ao criar o kanban!",
                        success: false
                    })
                }
            }
        },
        async getDepartments() {
            const resp = await api.getDepartments(this.user.channelId || this.user.roleId || this.user.roleId)
            if (resp.statusCode != 200) {
                this.departments = []
                return
            }

            if(this.user.role == "channel_manager" && this.user.departments?.at(0) !== 'all')
                resp.departments = resp.departments.filter(el => this.user.departments?.includes(el._id))

            this.departments = resp.departments
        },
        depName(_id) { // returns the name of the given _id department
            if(this.departments.length) {
                let dep = this.departments.find(el=>el._id == _id)
                if(dep)
                    return dep.name
            }
        },
        addStep() {
            const newStep = {
                name: '',
                isNew: true,
                channelId: this.user.channelId || this.user.roleId,
                order: parseInt(this.objAux.steps.length + 1 || 1)
            }
            if (this.objAux.steps)
                this.objAux.steps.push(newStep)
            else
                this.objAux.steps = [newStep]
        },
        deleteStep(step) {
            if (step.isNew)
                this.objAux.steps.splice(this.objAux.steps.findIndex(el => el == step), 1)
            else
                this.$set(step, 'delete', true)
        },
        selectKanban(kanban) {
            this.objAux = Object.assign({}, kanban)
            if (!this.objAux.steps) {
                this.objAux.steps = []
            }
        },
        async deleteKanban() {
            if (this.objAux._id) {
                await api.deleteKanban(this.objAux._id)
                this.getKanbans()
                this.$bvModal.hide('remove')
            }
        },
        viewKanban(kanban) {
            this.$router.replace({ path: '/manageKanban?id=' + kanban._id })
        }
    },
}
</script>